import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import profile from '../img/profile-img-grey.jpg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Airequestbutton from '../components/Airequestbutton';

function DisplayResults() {
  const [results, setResults] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [whattheydolist, setWhatTheyDoList] = useState([]);
  const [temperament, setTemperament] = useState([]);
  const [personality, setPersonality] = useState([]);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [_id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [expandedTemperaments, setExpandedTemperaments] = useState([]);
  const [expandedPersonality, setExpandedPersonality] = useState([]);
  const [showResults, setShowResults] = useState(true);
  const [showCareerList, setShowCareerList] = useState(true);
  const [showTemperament, setShowTemperament] = useState(true);
  const [showPersonality, setShowPersonality] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate('/');
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem('uuid');
      const username = localStorage.getItem('username');
      const _id = localStorage.getItem('_id');
      const email = localStorage.getItem('email');
      setUserId(newUuid);
      setUsername(username);
      setId(_id);
      setEmail(email);
    }
  }, []);

  useEffect(() => {
    const fetchStoredResults = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-raisec-results/${userId}`);
        setResults(response.data);
      } catch (error) {
        console.error('Error fetching stored results:', error);
      }
    };

    const fetchStoredCareers = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-career-results/${userId}`);
        setCareerList(response.data);
      } catch (error) {
        console.error('Error fetching stored careers:', error);
      }
    };

    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-Temperaments/${userId}`);
        setTemperament(response.data);
      } catch (error) {
        console.error('Error fetching stored temperaments:', error);
      }
    };

    const fetchStoredPersonality = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/p-stored-Personalities/${userId}`);
        setPersonality(response.data);
      } catch (error) {
        console.error('Error fetching stored personalities:', error);
      }
    };

    if (userId) {
      fetchStoredResults();
      fetchStoredCareers();
      fetchStoredTemperaments();
      fetchStoredPersonality();
    }
  }, [userId]);

  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments];
    newExpandedTemperaments[index] = !newExpandedTemperaments[index];
    setExpandedTemperaments(newExpandedTemperaments);
  };

  const handleTogglePersonalityDescription = (index) => {
    const newExpandedPersonality = [...expandedPersonality];
    newExpandedPersonality[index] = !newExpandedPersonality[index];
    setExpandedPersonality(newExpandedPersonality);
  };

  const handleToggleResults = () => {
    setShowResults(!showResults);
  };

  const handleToggleCareerList = () => {
    setShowCareerList(!showCareerList);
  };

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament);
  };

  const handleTogglePersonality = () => {
    setShowPersonality(!showPersonality);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('results-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`results-${username}.pdf`);
    });
  };

  return (
    <div className="Student-result">
      <div onClick={handleToggleResults} className="result-heading">
      <div className='return'>
            <button onClick={() => window.history.back()} className="back-btn">Back</button>
        </div>
        <h2>Results</h2>
        <div className="result-profile-img">
          <img src={profile} alt="profile" />
        </div>
        <h3>{username}</h3>
        <p>ID: lum-{_id}-nate</p>
        <p>{email}</p>
      </div>
      {showResults && (
        <div className="results-container" id="results-container">
          <div className="results-section">
            {results.length === 0 ? (
              <p>No results found</p>
            ) : (
              results.map((res, index) => (
                <div className="results-rep" key={index}>
                  <div className="results-upper">
                    <a>Student has a {res.score}% score in <strong>{res.area}</strong></a>
                  </div>
                  <div className="results-lower">
                    <p>Description: {res.description}</p>
                  </div>
                </div>
              ))
            )}
          </div>
       

          <div onClick={handleToggleCareerList} className="result-heading">
            <h2>Common careers associated with the above are:</h2>
          </div>
          {showCareerList && (
              <div className="student-career-list">
              {careerList.length === 0 ? (
                <p>No careers found</p>
              ) : (
                careerList.map((career, index) => (
                  <div className="student-career-rep" key={index}>
                    <p className="career-heading">{career.title}</p>
                    <p>What they do: {career.whatTheyDo}</p>
                  </div>
                ))
              )}
            </div>
          )}

          <div onClick={handleToggleTemperament} className="result-heading">
            <h2>Student Temperament</h2>
          </div>
          {showTemperament && (
            <div className="student-temperament-list">
              {temperament.map((temp, index) => (
                <div className="student-temperament-rep" key={index}>
                  <h3 onClick={() => handleToggleDescription(index)}>{index + 1}. {temp.temperamentName}</h3>
                  {expandedTemperaments[index] && <p>{temp.description}</p>}
                </div>
              ))}
            </div>
          )}

          <div onClick={handleTogglePersonality} className="result-heading">
            <h2>Student Personality</h2>
          </div>
          {showPersonality && (
            <div className="student-temperament-list">
              {personality.map((perso, index) => (
                <div className="student-temperament-rep" key={index}>
                  <h3 onClick={() => handleTogglePersonalityDescription(index)}>{index + 1}. {perso.personalityName}</h3>
                  {expandedPersonality[index] && <p>{perso.description}</p>}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      
      <div className='ai-download-btn'>
        <Airequestbutton />
        <button onClick={handleDownloadPDF} className='temp-btn'>Download</button>
      </div>
      
    </div>
  );
}

export default DisplayResults;
