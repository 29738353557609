import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import profile from '../img/profile-img-grey.jpg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function TemperamentsResults() {
  const [temperament, setTemperament] = useState([]);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [_id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [expandedTemperaments, setExpandedTemperaments] = useState([]);
  const [showTemperament, setShowTemperament] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate('/');
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem('uuid');
      const username = localStorage.getItem('username');
      const _id = localStorage.getItem('_id');
      const email = localStorage.getItem('email');
      setUserId(newUuid);
      setUsername(username);
      setId(_id);
      setEmail(email);
    }
  }, []);

  useEffect(() => {
    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-Temperaments/${userId}`);
        setTemperament(response.data);
      } catch (error) {
        console.error('Error fetching stored careers:', error);
      }
    };

    if (userId) {
      fetchStoredTemperaments();
    }
  }, [userId]);

  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments];
    newExpandedTemperaments[index] = !newExpandedTemperaments[index];
    setExpandedTemperaments(newExpandedTemperaments);
  };

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('results-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`results-${username}.pdf`);
    });
  };

  return (
    <div className="Student-result">
      <div onClick={handleToggleTemperament} className="result-heading">
      <div className='return'>
            <button onClick={() => window.history.back()} className="back-btn">Back</button>
        </div>
        <h2>Student Temperament</h2>
        <div className="result-profile-img">
            <img src={profile} alt="profile" />
        </div>
        <h3>{username}</h3>
        <p>ID: lum-{_id}-nate</p>
        <p>{email}</p>
      </div>

      {showTemperament && (
        <div className="student-temperament-list" id="results-container">
          {temperament.map((temp, index) => (
            <div className="student-temperament-rep" key={index}>
              <h3 onClick={() => handleToggleDescription(index)}>{index + 1}. {temp.temperamentName}</h3>
              {expandedTemperaments[index] && <p>{temp.description}</p>}
            </div>
          ))}
        </div>
      )}
      <button onClick={handleDownloadPDF} className='temp-btn'>Download</button>
    </div>
  );
}

export default TemperamentsResults;
