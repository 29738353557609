import React, { useState } from 'react';
import '../styles/Register.css';

const QuestionCard = () => {
  // State to track the selected answer
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <div className="question-card">
      <h2 className="question">How likely are you to eat fruits daily?</h2>
      <div className="answer-row">
        <div className="answer-item">
          <input
            type="radio"
            name="likelihood"
            id="very-likely"
            className="checkbox large green"
            checked={selectedOption === 'very-likely'}
            onChange={() => setSelectedOption('very-likely')}
          />
          <label htmlFor="very-likely">Very Likely</label>
        </div>
        <div className="answer-item">
          <input
            type="radio"
            name="likelihood"
            id="likely"
            className="checkbox medium green"
            checked={selectedOption === 'likely'}
            onChange={() => setSelectedOption('likely')}
          />
          <label htmlFor="likely">Likely</label>
        </div>
        <div className="answer-item">
          <input
            type="radio"
            name="likelihood"
            id="neutral"
            className="checkbox small yellow"
            checked={selectedOption === 'neutral'}
            onChange={() => setSelectedOption('neutral')}
          />
          <label htmlFor="neutral">Neutral</label>
        </div>
        <div className="answer-item">
          <input
            type="radio"
            name="likelihood"
            id="unlikely"
            className="checkbox medium red"
            checked={selectedOption === 'unlikely'}
            onChange={() => setSelectedOption('unlikely')}
          />
          <label htmlFor="unlikely">Unlikely</label>
        </div>
        <div className="answer-item">
          <input
            type="radio"
            name="likelihood"
            id="not-likely"
            className="checkbox large red"
            checked={selectedOption === 'not-likely'}
            onChange={() => setSelectedOption('not-likely')}
          />
          <label htmlFor="not-likely">Not Likely at All</label>
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
