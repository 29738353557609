import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import icon1 from '../img/test-icon1.png';
import icon2 from '../img/test-icon2.png';
import icon3 from '../img/test-icon3.png';
import '../styles/Tests.css';
import Instruction from './Instruct';

const Tests = () => {
  const navigate = useNavigate();
  const [raisecnumTests, setRaisecNumTests] = useState(null);
  const [temperamentnumTests, setTemperamentNumTests] = useState(null);
  const [personalitynumTests, setPersonalityNumTests] = useState(null);
  const [userId, setUserId] = useState('');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const [testname, setTestName] = useState('');
  // const [numTests, setNumTests] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const newUuid = localStorage.getItem('uuid');
    setUserId(newUuid);

    if (!token) navigate('/');
  }, [navigate]);

  const handleLogout = () => {
    localStorage.clear();
    toast.success('Logout successful');
    navigate('/');
  };

  useEffect(() => {
    const retrieveNumberOfDocs = async () => {
      try {
        const response = await axios.get('https://lumitestserver-raisec.vercel.app/retrieve-number-of-documents/');
        console.log('Number of documents:', response.data);
        // setNumTests(response.data);
      } catch (error) {
        console.error('Error fetching number of documents:', error);
      }
    };

    const retrieveNumberOfRaisecDocs = async () => {
      if (userId) {
        try {
          console.log(`Making request with UUID: ${userId}`); // Log the UUID being sent in the request
          const response = await axios.get(`https://lumitestserver-raisec.vercel.app/retrieve-number-of-raisec-documents/${userId}`);
          console.log('Number of raisec documents:', response.data);
          setRaisecNumTests(response.data);
        } catch (error) {
          console.error('Error fetching number of raisec documents:', error);
        }
      }
    };

    const retrieveNumberOfTemperamentDocs = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://lumitestserver-raisec.vercel.app/retrieve-number-of-temperament-documents/${userId}`);
          console.log('Number of temperament documents:', response.data);
          setTemperamentNumTests(response.data);
        } catch (error) {
          console.error('Error fetching number of temperament documents:', error);
        }
      }
    };

    const retrieveNumberOfPersonalityDocs = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://lumitestserver-raisec.vercel.app/p-retrieve-number-of-personality-documents/${userId}`);
          console.log('Number of personality documents:', response.data);
          setPersonalityNumTests(response.data);
        } catch (error) {
          console.error('Error fetching number of personality documents:', error);
        }
      }
    };

    retrieveNumberOfDocs();
    retrieveNumberOfRaisecDocs();
    retrieveNumberOfTemperamentDocs();
    retrieveNumberOfPersonalityDocs();
  }, [userId]);

  const tabs = [
    { name: 'All Tests', action: () => navigate('/general-results') },
    { name: raisecnumTests || 0, action: () => navigate('/raisec-test-results') },
    { name: personalitynumTests || 0, action: () => navigate('/personality-test-results') },
    { name: temperamentnumTests || 0, action: () => navigate('/temperament-test-results') }
  ];

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleTestClick = (url) => {
    // navigate(`/instructions?testUrl=${encodeURIComponent(url)}`);
    setTestName(url);
    setShowInstructions(true);
  };

  return (
    <div className="container">
      <div className={`sidebar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          {isSidebarExpanded ? '←' : '→'}
        </button>
        <div className='upper'>
        <div className="profile-section">
          <div className='profile-circle'>
            <h1 className="initial">{localStorage.getItem('username')[0].toUpperCase()}</h1>
          </div>
          <p className="username">{localStorage.getItem('username')}</p>
          <Link to="#" className="edit-profile">Edit Profile</Link>
        </div>
        </div>
        
        <div className="tabs">
          {tabs.map((tab, index) => (
            <button key={index} onClick={tab.action} className="tab-button">
              {tab.name}
            </button>
          ))}
        </div>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>

      {showInstructions ? (<Instruction testname={testname} />
      ) : (
      <div className="tests-available">
        <h2 className="section-title">SELECT A TEST TO GET STARTED</h2>
        <div className="test-cards">

          <div className="test-card">
            <img src={icon1} alt="Raisec Test" className="test-card-img" />
            <h3>Raisec Test</h3>
            <p>Discover your career inclinations based on interests and skills.</p>
            <button className="test-button" onClick={() => handleTestClick('raisec-test')}>Take Test</button>
            <Link className="read-more">Read More</Link>
          </div>

          <div className="test-card">
            <img src={icon2} alt="Personality Test" className="test-card-img" />
            <h3>Personality Test</h3>
            <p>Find out more about your personality traits.</p>
            <button className="test-button" onClick={() => handleTestClick('personality-test')} >Take Test</button>
            <Link className="read-more">Read More</Link>
          </div>

          <div className="test-card">
            <img src={icon3} alt="Temperament Test" className="test-card-img" />
            <h3>Temperament Test</h3>
            <p>Understand your temperament to enhance personal growth.</p>
            <button className="test-button" onClick={() => handleTestClick('temperament-test')}>Take Test</button>
            <Link className="read-more">Read More</Link>
          </div>

        </div>
      </div>
      )}
    </div>
  );
};

export default Tests;
