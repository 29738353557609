import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import { toast } from 'react-hot-toast';
import loginSideImage from '../img/login-image.jpg';
import registerSideImage from '../img/login-image.jpg';
import logo from '../img/kaeval-logo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Utility functions for local storage management
const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  localStorage.setItem(key, value);
  localStorage.setItem(`${key}_expiry`, now.getTime() + ttl);
};

const getWithExpiry = (key) => {
  const expiryStr = localStorage.getItem(`${key}_expiry`);
  if (!expiryStr) {
    return null;
  }
  const now = new Date();
  if (now.getTime() > parseInt(expiryStr, 10)) {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}_expiry`);
    return null;
  }
  return localStorage.getItem(key);
};

const Login = () => {
  // Separate state for login and register forms
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const [registerData, setRegisterData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreement: false
  });

  const [fade, setFade] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isLoggedIn = () => {
    const token = getWithExpiry('token');
    return token !== null;
  };

  useEffect(() => {
    const uuid = getWithExpiry('uuid');
    if (isLoggedIn()) {
      toast.error('You are already logged in');
      navigate(`/tests/${uuid}`);
    }
  }, [navigate]);

  // Update handler for login form
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value
    });
  };

  // Update handler for register form
  const handleRegisterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRegisterData({
      ...registerData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleLogin = async () => {
    setLoading(true); 
    try {
      const response = await axios.post('https://lumitestserver-raisec.vercel.app/login/', {
        email: loginData.email,
        password: loginData.password
      });
      const { username, token, _id, email, message, uuid } = response.data;

      setWithExpiry('username', username, 1209600000);
      setWithExpiry('token', token, 1209600000);
      setWithExpiry('_id', _id, 1209600000);
      setWithExpiry('email', email, 1209600000);
      setWithExpiry('uuid', uuid, 1209600000);

      toast.success(message);
      setLoginData({ email: '', password: '' });

      setTimeout(() => {
        navigate(`/tests/${uuid}`);
      }, 3000);
    } catch (error) {
      console.error('Login failed:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
        setLoading(false); // Set loading to false when done
      }
  };

  const handleRegister = async () => {
    if (!registerData.agreement) {
      toast.error('You must agree to the terms and conditions to register.');
      return;
    }
    if (registerData.username.length < 8) {
      toast.error('Username must be at least 8 characters long.');
      return;
    }
    if (registerData.password.length < 8) {
      toast.error('Password must be at least 8 characters long.');
      return;
    }
    if (registerData.password !== registerData.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    setLoading(true); 
    try {
      const response = await axios.post('https://lumitestserver-raisec.vercel.app/register/', {
        username: registerData.username,
        email: registerData.email,
        password: registerData.password
      });
      toast.success('Registration successful');
      setTimeout(() => {
        navigate('/');
      }, 3000);
      setRegisterData({ username: '', email: '', password: '', confirmPassword: '', agreement: false });
    } catch (error) {
      console.error('Registration failed:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
        setLoading(false);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      await handleRegister();
    } else {
      await handleLogin();
    }
  };

  const handleToggleForm = () => {
    setFade(true);
    setTimeout(() => {
      setIsRegistering(!isRegistering);
      setFade(false);
    }, 500);
  };

  const isLoginButtonDisabled = !loginData.email || !loginData.password;
  const isRegisterButtonDisabled = !registerData.username || !registerData.email || !registerData.password || !registerData.confirmPassword || !registerData.agreement;

  return (
    <div className={`outer-login-container ${fade ? 'fade-out' : 'fade-in'}`}>
      <div className={`login-container ${isRegistering ? 'register-mode' : ''}`}>
        <div className="left-section">
          <img
            src={isRegistering ? registerSideImage : loginSideImage} 
            alt="Side Image"
            className="login-image"
          />
        </div>
        <div className="right-outer-section">
          <div className="right-section">
            <form className="login-form" onSubmit={handleSubmit}>
              <h2>
                {isRegistering ? (<> <strong>Register</strong> </> ) : (<> <strong>Log</strong> In </> )} To Kaeval 
              </h2>

              {isRegistering ? (
                <div className='reg-side'>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      value={registerData.username}
                      onChange={handleRegisterChange}
                    />
                  </div>
                  <div className="input-container">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={registerData.email}
                      onChange={handleRegisterChange}
                    />
                  </div>
                  <div className="input-container">
                    <div className="password-wrapper">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={registerData.password}
                        onChange={handleRegisterChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="password-wrapper">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={registerData.confirmPassword}
                        onChange={handleRegisterChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  <div className="agreement-section">
                    <input
                      type="checkbox"
                      id="agreement"
                      name="agreement"
                      checked={registerData.agreement}
                      onChange={handleRegisterChange}
                    />
                    <label htmlFor="agreement" className='agreementp'>
                      By creating an account, you agree to Luminate's <Link className="terms-link" to="/legal">Terms of Services and Privacy Policy</Link>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={loginData.email}
                      onChange={handleLoginChange}
                    />
                  </div>
                  <div className="input-container">
                    <div className="password-wrapper">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={loginData.password}
                        onChange={handleLoginChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                </>
              )}

              <button type="submit" className="login-button"  disabled={isRegistering ? isRegisterButtonDisabled : isLoginButtonDisabled}>
                {loading ? (
                  <div className="spinner"></div>
                ) : isRegistering ? (
                  'Register'
                ) : (
                  'Login'
                )}
              </button>

              <p className="noregtag">
                {isRegistering ? 'Already have an account? ' : "Don't have an account? "}{" "}
                <span className="noreg" onClick={handleToggleForm}>
                  {isRegistering ? 'Login' : 'Register'}
                </span>
              </p>
              <div className="divider">- or -</div>
              <button disabled className="social-button google-button">
                {!isRegistering ? 'Login' : 'Register'} with Google
              </button>
              <button disabled className="social-button apple-button">
                {!isRegistering ? 'Login' : 'Register'} with Apple
              </button>
              <div className="kaeval-logo-image">
                <img src={logo} alt="logo" />
              </div>
            </form>
          </div>
          <div className="other-section"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
