import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/InstructionPopup.css';
import { FaClipboardList, FaClock, FaQuestionCircle } from 'react-icons/fa';

const InstructionPage = ({ testname}) => {
  const navigate = useNavigate();
  // const location = useLocation();

  // const params = new URLSearchParams(location.search);
  // const testUrl = params.get('testUrl');
  // const extractedUrl = testUrl ? testUrl.split('/').slice(1).join('/') : '';


  
  const handlenav = () => {
      navigate(`/${testname}`);
  };

  return (
    <div className="test-page">
      <div className='ins'>
      <p onClick={() => window.history.back()} className="back-text">Back</p>
      </div>
    <div className="heading-section">
      <h1 className="test-heading">You Are About To Take The {testname}</h1>
    </div>

    <div className="test-instructions">
      <div className="left-instructions">
        <h2>Test Instructions</h2>
        <p>Subheading Information</p>
        <p className="instructions">
          • Read each question carefully.<br />
          • Make sure you answer all questions.<br />
          • You have 30 minutes to complete the test.<br />
          • No external resources are allowed.<br />
        </p>
        <button onClick={handlenav} className="start-test-button">Start Test Now</button>
      </div>

      <div className="right-box">
        <div className="right-box-content">
          <div className="box-title">Tests</div>
          <hr className="underline" />
          <div className="test-icons">
              <div className="test-item"><FaClipboardList className="icon" /> <span className="text">Level: <strong>Basic</strong></span></div>
              <div className="test-item"><FaClock className="icon" /> <span className="text">Duration: <strong>Approx. 20 min</strong></span></div>
              <div className="test-item"><FaQuestionCircle className="icon" /> <span className="text"><strong>35 Questions</strong></span></div>
              <div className="test-item"><FaClipboardList className="icon" /> <span className="text">Add to your profile</span></div>
            </div>
        </div>
      </div>
    </div>

    <div className="mid-section">
      <h1 className="mid-heading">By taking this test you will be exploring:</h1>
      <p className="mid-paragraph">Please read the following instructions carefully before starting the test</p>
    </div>


    <div className="card-section">
      <div className="card">
        <h3>Yourself</h3>
        <p>Identify your interests, aptitudes, and inclinations to make informed decisions about subject selections, course trajectories, and educational pathways that align with your goals.</p>
      </div>
      <div className="card">
        <h3>Things you like to do</h3>
        <p>Evaluate your skills, values, and preferences to receive personalized recommendations for potential career paths that match your unique profile.</p>
      </div>
      <div className="card">
        <h3>Things you don't like</h3>
        <p>Gain valuable insights into your personality, strengths, weaknesses, and learning styles to identify areas for improvement and maximize your potential.</p>
      </div>
    </div>
  </div>
);
};

export default InstructionPage;
