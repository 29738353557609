// src/PredictButton.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PredictButton = () => {
    const [userId, setUserId] = useState('');
    const [result, setResult] = useState(null);

    useEffect(() => {
          const newUuid = localStorage.getItem('uuid');
          setUserId(newUuid);
    }, []);


    const handlePredict = async () => {
        try {
            const response = await axios.post('http://127.0.0.1:5000/ai-api/predict', { userId });
            setResult(response.data);
        } catch (error) {
            console.error('Error making prediction:', error);
        }
    };

    return (
        <div>
            <button className='ai-btn' onClick={handlePredict}>AI filter</button>
            {result && (
                <div>
                    <h3>Prediction Result</h3>
                    <p>Recommended Career: {result.recommended_career}</p>
                    <p>Model Accuracy: {result.accuracy}%</p>
                </div>
            )}
        </div>
    );
};

export default PredictButton;
