import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import '../styles/OnetTest.css';
import luminateLogo from '../img/luminate-logo.png';


const OnetTests = () => {
  const [questions, setQuestions] = useState([]);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [nextLink, setNextLink] = useState(null);
  const [prevLink, setPrevLink] = useState(null);
  const [answers, setAnswers] = useState({});
  const [returnedAnswers, setReturnedAnswers] = useState('');
  const [results, setResults] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [careerCode, setCareerCode] = useState([]);
  const [careerwhattheydo, setCareerwhattheydo] = useState([]);
  const [displayTests, setDisplayTests] = useState(true);
  const [displayResults, setDisplayResults] = useState(false);
  const [unanswered, setUnanswered] = useState(false);
  const [userId, setUserId] = useState('');

  const checkLoggedIn = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };

  const navigate = useNavigate();

  // Function to get username from session storage
  const getUsername = () => {
    return localStorage.getItem('username');
  };

  const getUserid = () => {
    return localStorage.getItem('uuid');
  };

  const id = () => {
    return localStorage.getItem('_id');
  };

  // Component usage example
  const isLoggedIn = checkLoggedIn();
  const username = getUsername();
  const _id = id();
  useEffect(() => {
    const newUuid = getUserid();
    setUserId(newUuid);
  }, []);

  const handleLogout = async () => {
    // Logout logic here
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('uuid');
    localStorage.removeItem('_id');
    toast.success('Logout successful');

    setTimeout(() => {
      navigate('/');
    }, 3000);
  };

  // const downloadQuestionsTxt = (questions) => {
  //   // Convert questions data to a string
  //   const questionsString = JSON.stringify(questions, null, 2);

  //   // Create a Blob from the string
  //   const blob = new Blob([questionsString], { type: 'text/plain' });

  //   // Create a link element, hide it, direct it towards the Blob, and then 'click' it programmatically
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = 'questions.txt';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
    


  // 1. on load retrieve questions from onnet endpoint
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/ws/mnm/interestprofiler/questions_30?start=${(currentPage - 1) * 12 + 1}&end=${currentPage * 12}`, {
          headers: {
            'Authorization': `Basic ${process.env.REACT_APP_AUTH_KEY}`,
            'Accept': 'application/json'
          }
        });
        setQuestions(response.data.question);
        // const downloadedQuestions = response.data.answer_options.answer_option.map(options => {
        //   return options.name;
        // })
        // downloadQuestionsTxt(downloadedQuestions);
        setAnswerOptions(response.data.answer_options.answer_option);
        setLoading(false);
        setNextLink(response.data.link.find(link => link.rel === 'next'));
        setPrevLink(response.data.link.find(link => link.rel === 'prev'));
      } catch (error) {
        console.error('Issue fetching questions:', error);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [currentPage]);

  const handleAnswerChange = (questionIndex, answerValue) => {
    setAnswers({ ...answers, [questionIndex]: answerValue });
  };

  const validateAnswers = () => {
    const unansweredQuestions = questions.some(question => !answers[question.index]);
    setUnanswered(unansweredQuestions);
    return !unansweredQuestions;
  };

  // 2. on submit send the answers checked to onnet endpoint
  const handleSubmit = async () => {
    // console.log('Submitting answers:', answers);
    if (!validateAnswers()) {
      toast.error('Please answer all questions before submitting.');
      return;
    }
    // later on change and remove this enpoint, a simple split operation would convert the string into an array 
    // rather than making a reqiuest to the submit enpoin to do so
    try {
      const response = await axios.post('https://lumitestserver-raisec.vercel.app/submited-answerss/', answers);
      const returnedAnswersarray = response.data.answersArray;
      const answersString = returnedAnswersarray.join('');
      setReturnedAnswers(answersString);
      console.log('Answers string returned:', answersString);
      // setAnswers({});
      toast.success('Submission Successful');
      setTimeout(() => {
        navigate('/');
    }, 2000);
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  // 3. retrieve and fetch onnet processed results and careers from onnet endpoint based on answers sent to the endpoint
  // 4. set the results to a state and save the results and careers to the database

  // const downloadFile = (data, filename) => {
  //   const json = JSON.stringify(data, null, 2);
  //   const blob = new Blob([json], { type: 'application/json' });
  //   const href = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = href;
  //   link.download = filename;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  useEffect(() => {
    const fetchResultsAndCareers = async () => {
      try {
        if (returnedAnswers) {
          const [resultResponse, careerResponse] = await Promise.all([
            axios.get(`https://lumitestserver-raisec.vercel.app/ws/mnm/interestprofiler/results?answers=${returnedAnswers}`, {
              headers: {
                'Authorization': `Basic ${process.env.REACT_APP_AUTH_KEY}`,
                'Accept': 'application/json'
              }
            }),
            axios.get(`https://lumitestserver-raisec.vercel.app/ws/mnm/interestprofiler/careers?answers=${returnedAnswers}`, {
              headers: {
                'Authorization': `Basic ${process.env.REACT_APP_AUTH_KEY}`,
                'Accept': 'application/json'
              }
            })
          ]);

          setResults(resultResponse.data);
          setCareerList(careerResponse.data);
          // downloadFile(resultResponse.data, 'resultResponse.json');
          // downloadFile(careerResponse.data, 'careerResponse.json');
          console.log("results first", resultResponse.data)
          console.log("careerlist first", careerResponse.data.career)
          const refcode = careerResponse.data.career.map(refs => refs.code);
          console.log('codes received:', refcode);
          console.log('Results and career list fetched successfully');
          setCareerCode(refcode);

          // Fetch career details and then save to DB
          const careerDetails = await fetchCareerwhattheydo(refcode);
          await saveresultstodb(resultResponse.data, userId);
          await savecareertodb(careerResponse.data, careerDetails, userId);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            navigate('/');
          }, 2000);
        }
      } catch (error) {
        console.error('Error fetching results and careers:', error);
      }
    };

    fetchResultsAndCareers();
  }, [returnedAnswers, userId]);

  // Fetch career details based on career codes
  const fetchCareerwhattheydo = async (careerCodes) => {
    try {
      console.log('Career codes:', careerCodes);
      const response = await axios.post('https://lumitestserver-raisec.vercel.app/code/', careerCodes);
      const results = response.data.map(item => item.what_they_do);
      console.log('Career details fetched:', results);
      setCareerwhattheydo(results);
      return results;
    } catch (error) {
      console.error('Error fetching career details:', error);
      return [];
    }
  };

  // 5. function that saves the results to the database
  const saveresultstodb = async (results, uuid) => {
    try {
      console.log('Results to send and save:', results);
      if (results && results.result) {
        const infoArray = results.result.map(item => ({
          area: item.area,
          score: item.score,
          description: item.description
        }));
        await axios.post(`https://lumitestserver-raisec.vercel.app/raisec-results/${uuid}`, { infoArray });
      } else {
        console.log('Results is undefined');
      }
    } catch (error) {
      console.error('Error saving results:', error);
    }
  };

  // 6. function that saves the careers to the database
  const savecareertodb = async (careerList, careerDetails, uuid) => {
    try {
      console.log('Careerlist client:', careerList);
      console.log('Career details client:', careerDetails);
      // Limit to top 10 careers
      const filteredCareers = careerList?.career.filter(career => career.fit === 'Best').slice(0, 10);
      const limitedCareerDetails = careerDetails.slice(0, 10);

      if (filteredCareers && filteredCareers.length > 0) {
        const careerinfoarray = filteredCareers.map((career, index) => ({
          title: career.title,
          whatTheyDo: limitedCareerDetails[index]
        }));
        await axios.post(`https://lumitestserver-raisec.vercel.app/careers-results/${uuid}`, { careerinfoarray });
      } else {
        console.log('No careers with fit as "Best" found');
      }
    } catch (error) {
      console.error('Error saving career list:', error);
    }
  };

  useEffect(() => {
    if (displayResults) {
      const fetchStoredResults = async () => {
        try {
          const storedResults = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-raisec-results/${userId}`);
          console.log('Stored results:', storedResults.data);
          // setStoredResults(storedResults.data);
        } catch (error) {
          console.error('Error fetching stored results:', error);
        }
      };

      const fetchStoredCareers = async () => {
        try {
          const storedCareers = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-career-results/${userId}`);
          console.log('Stored careers:', storedCareers.data);
          // setStoredCareer(storedCareers.data);
        } catch (error) {
          console.error('Error fetching stored careers:', error);
        }
      };

      fetchStoredResults();
      fetchStoredCareers();
    }
  }, [displayResults, userId]);

  if (loading) return (
    <div className='loader'>
      <svg className="loader" width="200" height="200" viewBox="0 0 240 240">
        <circle className="loader-ring loader-ring-a" cx="120" cy="120" r="105" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 660" strokeDashoffset="-330" strokeLinecap="round"></circle>
        <circle className="loader-ring loader-ring-b" cx="120" cy="120" r="35" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 220" strokeDashoffset="-110" strokeLinecap="round"></circle>
        <circle className="loader-ring loader-ring-c" cx="85" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeLinecap="round"></circle>
        <circle className="loader-ring loader-ring-d" cx="155" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeLinecap="round"></circle>
      </svg>
    </div>
  );

  return (
    <div>

            <div className="r-back-button">
            {prevLink && <p className='up-nex-button' onClick={() => setCurrentPage(currentPage - 1)}>Prev</p>}
            {nextLink && <p className='up-nex-button' onClick={() => setCurrentPage(currentPage + 1)}>Next</p>}
            </div>
      
        <div className='raisec-intro'>
              <h1>Interest Profiler/Raisec Test</h1>
              <p>Answer <strong>all</strong> the following questions based on your preference. Try to avoid choosing the middle answers for more accurate results.</p>
          </div>
    <div className="tests-container">
      {displayTests && (
        <>
          {questions.map((question, index) => (
            <Fade direction='top' duration={1000} key={question.index}>
              <div className="raisec-question-container">
                <h3>{question.index}. {question.text}</h3>
                <div className="answer-container">
                {answerOptions.map((option, idx) => (
                      <label key={option.id} className={`answer-option`}>
                        <input
                          type="radio"
                          className={`checkbox ${
                            idx === 0 || idx === 4
                              ? "large"
                              : idx === 1 || idx === 3
                              ? "medium"
                              : "small"
                          } ${
                            idx <= 1 ? "red" : idx === 2 ? "yellow" : "green"
                          }`}
                          name={`question-${question.index}`}
                          value={option.value}
                          checked={answers[question.index] === option.value}
                          onChange={() => handleAnswerChange(question.index, option.value)}
                        />
                        <span className="answer-text">{option.name}</span>
                      </label>
                    ))}
                </div>
              </div>
            </Fade>
          ))}
          <div>
            {unanswered && (
              <Fade direction='top' duration={1000}>
                <p className='unanswered'>Please answer all questions</p>
              </Fade>
            )}
            <div className='pagination'>
              {prevLink && <button className='nex-button' onClick={() => setCurrentPage(currentPage - 1)}>Prev</button>}
              {nextLink && <button className='nex-button' onClick={() => setCurrentPage(currentPage + 1)}>Next</button>}
              {!nextLink && <button onClick={handleSubmit} className="submit-button">Submit Answers</button>}
            </div>
          </div>
        </>
      )}
    </div>
    </div>
  );
};

export default OnetTests;
